<template>
  <div class='uppercase w-full overflow-x-auto text-xs'>
    <div class='flex'>
      <label v-for='option in options'
        :key='option'
        class='text-left px-2 py-1 text-gray-700 font-light whitespace-no-wrap flex flex-row justify-start items-center gap-x-1'>
         <input type='checkbox'
          class='form-checkbox'
          :name='option'
          :value='option'
          @change='changeOptions'
          v-model='checkedOptions'>
          {{ optionText(option) }}
      </label>
      <select v-model='filterTemplateId'
        v-if='filteredByCustomField'
        class='form-select w-50'>
        <option v-for='template in templatesForFiltering' 
          :key='template.id' 
          :value='template.id'>
          {{template.custom_field_name}}
        </option>
      </select>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'HistoryFiltersMobile',
  data () {
    return {
      filterTemplateId: 0,
      options: [
        'comment',
        'investment',
        'custom field',
      ],
      checkedOptions: ['comment'],
    }
  },
  watch: {
    filterParams: {
      handler: function (newVal) {
        this.changeFilterParams(newVal)
      }
    }
  },
  computed: {
    ...mapState('investments', [
      'drilldownInvestmentCustomFields',
    ]),
    ...mapGetters('investments', [
      'drilldownInvestmentId',
    ]),
    ...mapState('customFields', [
      'customFieldTemplates',
    ]),
    filteredByCustomField () {
      return this.checkedOptions.includes('custom field')
    },
    templatesForFiltering () {
      let templates = []
      if (this.drilldownInvestmentCustomFields.length > 0) {
        templates = this.drilldownInvestmentCustomFields.slice(0)
      } else {
        templates = this.customFieldTemplates.slice(0)
      }
      templates.unshift({id: 0, custom_field_name: 'show all'})
      return templates
    },
    filterParams () {
      return { filterText: this.checkedOptions,
               customFieldTemplateId: this.filterTemplateId,
               drilldownInvestmentId: this.drilldownInvestmentId }
    }
  },
  methods: {
    optionText (optionValue) {
      switch (optionValue) {
        case 'custom field':
          return 'data'
        case 'investment':
          return 'created at'
        default:
        return `${optionValue}s`
      }
    },
    changeOptions () {
      this.$emit('change-options', this.checkedOptions)
    },
    changeFilterParams (filterParams) {
      this.$emit('change-filter-params', filterParams)
    },
  },
}
</script>
