<template>
  <div class='relative'>
    <vue-editor
      v-model='newMessage'
      placeholder='new comment'
      :editor-options='editorOptions'>
    </vue-editor>
    <button
      class='border rounded-md w-24 h-10 text-white text-sm font-semibold absolute'
      style='background-color: #223645; top: .33rem; right: .25rem;'
      :disabled='emptyComment'
      @click='submit()'>
      추가
    </button>
    <label class='cursor-pointer w-full text-sm rounded-md pl-4 py-3 pr-32'>
      {{ uploadText }}
      <input @change='fileSelect' type='file' ref='AttachedFile' class='bg-pink-200 hidden'>
    </label>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { VueEditor } from 'vue2-editor'

export default {
  name: 'CommentInput',
  props: ['investmentId'],
  components: {
    VueEditor,
  },
  data () {
    return {
      editorFocused: null,
      editorOptions:{
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline'],
              [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
              ['clean'],
            ],
          }
        }
      },
      newMessage: '',
      newFile: '',
      fileInput: null,
    }
  },
  computed: {
    emptyComment () {
      return this.newMessage.trim() === ''
    },
    uploadText () {
      if (this.hasFile) {
        return this.fileInput.name
      } else {
        return '[ Upload new file ]'
      }
    },
    hasFile () {
      return this.fileInput
    },
  },
  methods: {
    ...mapActions('changeLogs', [
      'createComment',
    ]),
    submit () {
      let comment = {
        investmentId: this.investmentId,
        value:        this.newMessage,
        attachedFile: this.fileInput
      }
      this.createComment(comment).then(() => {
        this.newMessage = ''
        this.fileInput = null
        this.clearLocalStorage()
      })
    },
    fileSelect () {
      this.fileInput = this.$refs.AttachedFile.files[0]
    },
    clearLocalStorage () {
      localStorage.removeItem('editingCommentInvestmentId')
      localStorage.removeItem('editingCommentContent')
    },
  },
  mounted () {
    // if we have localstorage data, set it here, and remove it from local storage
    let localStorageId      = localStorage.getItem('editingCommentInvestmentId')
    let localStorageComment = localStorage.getItem('editingCommentContent')
    if (localStorageId && 
        (parseInt(localStorageId) === this.investmentId) && 
        localStorageComment) {
      this.newMessage = localStorageComment
      this.clearLocalStorage()
    }
  },
  beforeDestroy () {
    if (!this.emptyComment) {
      localStorage.setItem('editingCommentInvestmentId', this.investmentId)
      localStorage.setItem('editingCommentContent', this.newMessage)
    }
  }
}
</script>
