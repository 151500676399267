<template>
  <div class='pl-2'>
    <div class='text-xs py-2 flex flex-row justify-start items-center gap-x-1'>
      <div class='text-gray-900'>{{ timestampString }}</div>
      <div class='uppercase'>{{ editPersonString }}</div>
      <div class='text-blue-600'>{{ changeTypeString }}</div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'HistoryItem',
  props: [ 'log' ],
  computed: {
    objectLabel () {
      if (this.log.event_object === 'investment') {
        return 'investment'
      } else {
        return this.log.event_object
      }
    },
    timestampString () {
      return `${dayjs(this.log.created_at).format('YYYY.MM.DD HH:mm')}`
    },
    editPersonString () {
      return `${this.log.username}`
    },
    changeTypeString () {
      return `${this.log.event_type} ${this.objectLabel}`
    },
  },
}
</script>
