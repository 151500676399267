<template>
  <div class='relative'>
    <div v-if='isDeleteInvestment'>
      deleted {{objectLabel}}
    </div>
    <div v-else-if='isUpdateInvestment'>
      <div v-if='!!log.value_after.entity_id' class='flex flex-row'>
        <label class='w-32 text-gray-600'>Entity</label>
        <div>
          {{log.value_before.entity_name}}
          <arrow-narrow-right-icon class='inline-block mx-2' />
          {{log.value_after.entity_name}}
        </div>
      </div>
      <div v-if='!!log.value_after.investment_name' class='flex flex-row'>
        <label class='w-32 text-gray-600'>{{objectLabel}} Name</label>
        <div>
          {{log.value_before.investment_name}}
          <arrow-narrow-right-icon class='inline-block mx-2' />
          {{log.value_after.investment_name}}
        </div>
      </div>
      <div v-if='!!log.value_after.owner_ids' class='flex flex-row'>
        <label class='w-32 text-gray-600'>담당자</label>
        <div>
          {{getUserNames(log.value_before.owner_ids)}}
          <arrow-narrow-right-icon class='inline-block mx-2' />
          {{getUserNames(log.value_after.owner_ids)}}
        </div>
      </div>
    </div>
    <div v-else-if='isCreateInvestment'>
      <div class='flex flex-row'>
        <label class='w-32 text-gray-600'>Entity</label>
        <div>{{log.value_after.entity_name}}</div>
      </div>
      <div class='flex flex-row'>
        <label class='w-32 text-gray-600'>{{objectLabel}} Name</label>
        <div>{{log.value_after.investment_name}}</div>
      </div>
      <div class='flex flex-row'>
        <label class='w-32 text-gray-600'>담당자</label>
        <div>{{getUserNames(log.value_after.owner_ids)}}</div>
      </div>
    </div>
    <div v-else-if='isCustomField'>
      <span class='inline-block w-32 text-gray-600'>{{log.custom_field_name}}</span>
      <span v-if='!!log.value_before'>
        <span v-if='hasValueBeforeUrl'>
          <a :href='log.value_before_url'
            download>{{log.value_before}}</a>
        </span>
        <span v-else>{{log.value_before}}</span>
        <arrow-narrow-right-icon class='inline-block mx-2' />
      </span>
      <span v-if='hasValueAfterUrl'>
          <a :href='log.value_after_url'
            download>{{log.value_after}}</a>
        </span>
      <span v-else>{{log.value_after}}</span>
    </div>
    <div v-else-if='isMeetingNote' 
      @click='openMeetingNoteEdit(log.value_after.meeting_note_id)'
      class='hover:shadow-md'>
      <span class='inline-block w-32 text-gray-800 font-semibold'>{{log.value_after.title}}</span>
      <div class='flex flex-row'>
        <div v-html='log.value_after.memo'></div>
      </div>
    </div>
    <div v-else-if='isComment'>
      <div v-if='isEditing'>
        <div class='fixed lg:static bg-white z-30' style='top:0%; width:100%; left:0%'>
          <div class='bg-cover fixed lg:hidden top-0 left-0 h-full w-full z-10 opacity-70' style='background-color: #223645;'></div>
          <comment-input-edit
            :comment='log' 
            @done-editing='toggleEdit' 
            class='comment-input-edit bg-white z-20' />
        </div>
      </div>
      <div v-else>
        <div class='ql-editor linked-text-container mobile-content-view' v-linkified v-html='log.value_after'></div>
        <div class='absolute flex flex-row justify-end items-center gap-x-1' style='top:-2rem; right:0.25rem;'>
          <button
            @click='toggleSharing'
            class='p-2 rounded-md bg-gray-100'>
            <share-icon class='h-4' />
          </button>
          <button v-if='showEditControl'
            @click='toggleEdit'
            class='p-2 rounded-md bg-gray-100'>
            <pencil-icon class='h-4' />
          </button>
          <button v-if='showDeleteControl'
            @click='confirmDeleteComment(comment)'
            class='p-2 rounded-md bg-gray-100'>
            <x-icon class='h-4' />
          </button>
        </div>
      </div>
      <div v-if='hasCommentFileUrl' 
        class='flex flex-row items-center gap-x-2 mr-2 ml-1 px-1 py-2 text-xs rounded-md bg-gray-100 text-gray-800'
        @click='openFileInApp(log.comment_file_url)'>
        <document-download-icon class='h-5' />
        {{log.comment_file}}
      </div>
    </div>
  </div>

</template>

<script>

import dayjs from 'dayjs'
import { mapState, mapActions } from 'vuex'
import { ArrowNarrowRightIcon, PencilIcon, DocumentDownloadIcon, ShareIcon} from '@vue-hero-icons/outline'
import {  XIcon } from '@vue-hero-icons/solid'

import CommentInputEdit from '@/components/CommentInputEdit.vue'


export default {
  name: 'ChangeLogMobile',
  components: {
    CommentInputEdit,
    ArrowNarrowRightIcon,
    DocumentDownloadIcon,
    PencilIcon,
    XIcon,
    ShareIcon,
  },
  props: ['log', 'isInvestment'],
  data () {
    return {
      isEditing: false,
    }
  },
  computed: {
    ...mapState('users', [
      'users',
    ]),
    objectLabel () {
      return 'investment'
    },
    hasValueAfterUrl () {
      return this.log.value_after_url.length > 0
    },
    hasValueBeforeUrl () {
      return this.log.value_before_url.length > 0
    },
    hasCommentFileUrl () {
      return this.log.comment_file_url.length > 0
    },
    isWrittenToday () {
      let today = dayjs(dayjs().format('YYYY-MM-DD'))
      let createdAt = dayjs(this.log.created_at).format('YYYY-MM-DD')
      return today.isSame(createdAt)
    },
    isMyComment () {
      return this.log.is_my_change
    },
    comment () {
      return {
        investmentId: this.log.investment_id,
        id:           this.log.id
      }
    },
    isDeleteInvestment () {
      return this.logType === 'delete investment'
    },
    isUpdateInvestment () {
      return this.logType === 'update investment'
    },
    isCreateInvestment () {
      return this.logType === 'create investment'
    },
    isCustomField () {
      return this.logType === 'custom field'
    },
    isMeetingNote () {
      return this.logType === 'meeting note'
    },
    isComment () {
      return this.logType === 'comment'
    },
    logType () {
      // investment
      if (this.log.event_type.includes("delete") && (this.log.event_object != "custom field" && this.log.event_object != "comment" && this.log.event_object != "meeting note"))
        return 'delete investment'
      else if (this.log.event_type.includes("update") && (this.log.event_object != "custom field" && this.log.event_object != "comment" && this.log.event_object != "meeting note"))
        return 'update investment'
      else if (this.log.event_type.includes("create") && (this.log.event_object != "custom field" && this.log.event_object != "comment" && this.log.event_object != "meeting note"))
        return 'create investment'
      // custom_field
      else if (this.log.event_object === "custom field")
        return 'custom field'
      // comment
      else if (this.log.event_object === "comment")
        return 'comment'
      // meeting note
      else if (this.log.event_object === "meeting note")
        return 'meeting note'
      else
        return ''
    },
    showEditControl () {
      return this.isMyComment && !this.isEditing
    },
    showDeleteControl () {
      return this.isMyComment
    },
    sharedCommentUrl () {
      return `${process.env.VUE_APP_CLIENT_BASE_URL}/comment-view?id=${this.log.id}&comment_date=${ dayjs(this.log.created_at).format('YYYYMMDD')}`
    },
  },
  methods: {
    ...mapActions('changeLogs', [
      'deleteComment',
    ]),
    ...mapActions('meetingNotes', [
      'getMeetingNoteDetails',
    ]),
    toggleEdit() {
      this.isEditing = !this.isEditing
    },
    toggleSharing() {
      this.$emit('toggle-sharing', this.sharedCommentUrl)
    },
    confirmDeleteComment (comment) {
      this.$confirm(`comment 삭제하시겠습니까?`, 'Warning', {
        confirmButtonText: '삭제',
        cancelButtonText: '취소',
        type: 'warning'
      }).then(() => {
        this.deleteComment(comment).then(() => {
          this.$message({
            type: 'success',
            message: '삭제 완료'
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '삭제 취소'
        })
      })
    },
    getUserName (userId) {
      let found = this.users.find(user => user.id === userId)
      return (found) ? found.name : userId
    },
    getUserNames (userIds) {
      return !userIds ? '' : userIds.map(userId => this.getUserName(userId))
    },
    openMeetingNoteEdit (meetingNoteId) {
      this.getMeetingNoteDetails(meetingNoteId)
    },
    openFileInApp (details) {
      /* eslint-disable */
      try { 
        const fileUrl = details
        const fileName = details.split('/').pop()
        const fileExtension = fileName.split('.').pop()
        const fileToOpen = {
            url: fileUrl,
            fileName: fileName,
            fileExtension: fileExtension,
        }
        const message = JSON.stringify(fileToOpen)
        openFile.postMessage(message)
        console.log('send fileOpen channel message: ', message)
        
        // keep the old openFile post message for backward compatibility
        openFile.postMessage(details)
      } catch (err) { 
        console.log('error object from postMessage for open file in app:', err)
      }
      /* eslint-enable */
    },
  }
}
</script>

<style lang='scss'>
.comment-input-edit {
  height: 100vh;
}

.comment-input-edit .ql-container {
  height: calc(100% - 48px);
}

.ql-editor.mobile-content-view {
  @apply p-0;
  min-height: 5rem;
}

</style>
