<template>
  <div>
    <portal to='modal-header'>
      <h1 class='text-2xl font-semibold px-8 py-8'>Comment 공유:</h1>
    </portal>
    <div class='flex px-2 py-2'>
      <link-icon class='' />
      <p class='text-2l px-2'>{{ shareableLink }}</p>
    </div>
    <portal to='modal-footer'>
      <div class='text-center py-4'>
        <button
          class='rounded-md px-4 py-2 text-white text-xs font-semibold '
          style='background-color: #223645;'
          @click='copyToClipboard'>
          URL 복사
        </button>
      </div>
    </portal>
  </div>
</template>

<script>
import { LinkIcon }        from '@vue-hero-icons/outline'
export default {
  name: 'CommentShareModal',
  components: {
    LinkIcon,
  },
  props: [
    'shareableLink',
    'title',
  ],
  methods: {
    copyToClipboard () {
      this.$copyText(this.shareableLink).then(() => {
        this.$message({
          type: 'success',
          message: 'URL를 클립보드에 복사 됨.',
        })
        this.$emit('modal-close')
      })
    },
  }
};
</script>