<template>
  <div>
    <comment-input :investment-id='drilldownInvestmentId' />
    <history-filters
      class='my-4'
      @change-options='changeOptions' 
      @change-filter-params='changeFilterParams' />
    <div v-if='meetingNoteChecked'
      class='mb-2'>
      <meeting-note-card v-for='meetingNote in meetingNotes'
        :meetingNote='meetingNote'
        :key='`updates-meeting-mote-${meetingNote.id}`'
        @click.native='openMeetingNoteEdit(meetingNote.id)' />
    </div>
    <div v-if='hasLogs'>
      <history-item
        v-for='log in changeLogs'
        :key='`changeLogs-${log.id}`'
        :log='log'
        class='mb-6 border-t border-color-gray-400'>
        <change-log
          :log='log'
          @toggle-sharing='toggleSharing'/>
      </history-item>
      <portal to='modals'>
        <comment-share-modal
          :shareable-link='shareableLink'
          @modal-close='modalClose' />
      </portal> 
    </div>
    <div v-else 
      class='max-w-3xl mx-4 p-8 lg:p-12 text-center bg-gray-100 rounded-md text-sm lg:text-base'>
      Empty! Please update your filters.
    </div>
  </div>
</template>

<script>
import ChangeLog       from '@/components/mobile/ChangeLogMobile.vue'
import HistoryItem     from '@/components/mobile/HistoryItem.vue'
import CommentInput    from '@/components/CommentInput.vue'
import HistoryFilters  from '@/components/mobile/HistoryFiltersMobile.vue'
import MeetingNoteCard from '@/components/meeting_notes/MeetingNoteCard.vue'
import CommentShareModal  from '@/components/investment/CommentShareModal.vue'

import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'InvestmentHistoryMobile',
  components: {
    ChangeLog,
    CommentInput,
    HistoryFilters,
    HistoryItem,
    MeetingNoteCard,
    CommentShareModal,
  },
  data () {
    return {
      filterParams: {
        filterText: ['comment', 'meeting note'],
        customFieldTemplateId: 0,
        drilldownInvestmentId: 0
      },
      shareableLink: '',
      checkedOptions: ['comment', 'meeting note'],
    }
  },
  watch: {
    drilldownInvestmentId: {
      handler: function (newId) {
        this.filterParams.drilldownInvestmentId = newId
        this.filterChangeLogs(this.filterParams)
        this.getInvestmentMeetingNotes(newId)
      },
      immediate: true
    },
  },
  computed: {
    ...mapGetters('investments', [
      'drilldownInvestmentId'
    ]),
    ...mapState('changeLogs', [
      'changeLogs',
    ]),
    ...mapState('meetingNotes', [
      'meetingNotes',
    ]),
    hasLogs () {
      return this.changeLogs.length > 0
    },
    meetingNoteChecked () {
      return this.checkedOptions.includes('meeting note')
    },
  },
  methods: {
    ...mapActions('changeLogs', [
      'getChangeLogs',
      'filterChangeLogs',
    ]),
    ...mapActions('meetingNotes', [
      'getInvestmentMeetingNotes',
      'getMeetingNoteDetails',
    ]),
    changeOptions (checkedOptions) {
      this.checkedOptions = checkedOptions
    },
    changeFilterParams (filterParams) {
      this.filterChangeLogs(filterParams)
      if (this.meetingNoteChecked) {
        this.getInvestmentMeetingNotes(this.drilldownInvestmentId)
      }
    },
    openMeetingNoteEdit (meetingNoteId) {
      this.getMeetingNoteDetails(meetingNoteId)
    },
    toggleSharing(link) {
      this.shareableLink = link
      this.$store.dispatch('modalOpen', {height: 16, width: 24})
    },
    modalClose () {
      this.$store.dispatch('modalClose')
    },
  },
}
</script>
