<template>
  <div class='px-4 py-6 border rounded-md my-4 relative max-w-lg'>
    <h1 class='text-xl uppercase'>Meeting Note</h1>
    <h1 class='text-2xl uppercase text-gray-900 font-semibold'>{{meetingNote.title}}</h1>
    <div class='flex flex-row mt-8'>
      <div class='flex-grow flex-shrink-0 w-1/2'>
        <h1 class='text-md uppercase text-gray-900 font-semibold'>작성자:{{getOwnerName()}}</h1>
        <div class='flex flex-row mt-4'>
          <h3>참석자:</h3>
          <h3 class='text-base text-gray-600'
            v-for='contact in meetingNote.contacts'
            v-bind:key='`meeting-note-card-contact-${contact.id}`'>{{contact.contact_name}}, </h3>
        </div>
      </div>
      <div class='flex-grow flex-shrink-0 w-1/2'>
        <h4 class='mb-1 text-sm text-gray-600'><calendar-icon class='inline-block mr-2 text-gray-300 h-5' :stroke-width='1' style='margin-top: -5px;' />date: {{meetingNote.meeting_date}}</h4>
      </div>
    </div>
    <div v-if='editable' class='absolute' style='right: 1.5rem; top: 1.5rem;'>
      <button @click='setMeetingNote(meetingNote)' class='mr-4'><pencil-alt-icon class='h-4' /></button>
      <button @click='deleteMeetingNote(meetingNote.id)' class=''><trash-icon class='h-4' /></button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { CalendarIcon,
         PencilAltIcon,
         TrashIcon } from '@vue-hero-icons/outline'

export default {
  name: 'MeetingNoteCard',
  components: {
    CalendarIcon,
    PencilAltIcon,
    TrashIcon,
  },
  props: {
    meetingNote: Object,
    editable: Boolean,
  },
  computed: {
    ...mapState('users', [
      'users',
    ]),
  },
  methods: {
    deleteMeetingNote (meetingNoteId) {
      this.$emit('delete-meeting-note', meetingNoteId)
    },
    setMeetingNote (meetingNote) {
      this.$emit('set-edit-meeting-note', meetingNote)
    },
    getOwnerName () {
      let found = this.users.find(user => user.id === this.meetingNote.owner_id)
      return (found) ? found.name : ''
    },
  }
}
</script>
